/* eslint-disable complexity */
import { IRoles } from 'services/models/IRoles';
import { IUser } from 'services/models/IUser';
import { IEditUserFormModel } from 'models/EditUserFormModel';
import { parseString } from 'utils/commonUtils';
import { parseLoanOfficer } from './loanOfficers';

export function parseEditUserFormToBody(userData: IEditUserFormModel) {
  const {
    isActive,
    first_name,
    middle_name,
    last_name,
    title,
    url_name,
    role,
    nmls,
    region,
    branchUuid,
    email,
    secondaryEmail,
    phone_primary,
    los_name,
    crmd_uid,
    credit_login_username,
    credit_login_password,
    loan_officer,
    branches,
    receive_welcome_email,
    assignedOfficers,
    enable_automatic_assignment,
    pricing_engine_username,
    enable_rate_quote,
    enable_plaid,
    enable_credit_score,
    enable_du,
    enable_du_prequal
  } = userData;
  let pbranches: string[];

  if (branchUuid?.length > 0) {
    pbranches = [branchUuid];
  }
  if (role === IRoles.ASSISTANT) {
    pbranches = branches;
  }
  return {
    pricingEngineUsername: pricing_engine_username,
    isActive: isActive,
    firstName: first_name,
    middleName: middle_name,
    lastName: last_name,
    title: title,
    urlName: url_name,
    role: role,
    nmls: nmls,
    region: region,
    branchesUuids: pbranches,
    email: email,
    primaryPhone: phone_primary,
    losName: los_name,
    crmdUid: crmd_uid,
    assistantOf: loan_officer.map((x) => x.uuid),
    sendWelcomeEmail: receive_welcome_email,
    creditReportUsername: credit_login_username,
    creditReportPassword: credit_login_password,
    secondaryEmail: secondaryEmail,
    automaticAssignmentEnabled: enable_automatic_assignment,
    settings: {
      plaidEnabled: enable_plaid,
      isCreditScoreEnabled: enable_credit_score,
      isDuEnabled: enable_du,
      isRateQuoteEnabled: enable_rate_quote,
      duAutomaticPreApproval: enable_du_prequal
    },
    automaticAssignment: assignedOfficers.map(({ officer, state }) => {
      return {
        officerUUID: officer.uuid,
        states: state.map((s: any) => s?.abbreviation)
      };
    })
  };
}

export function parseUserModelToForm(userData: IUser): IEditUserFormModel {
  const {
    firstName,
    middleName,
    lastName,
    primaryPhone,
    email,
    profilePicture,
    role,
    officerInfo,
    isActive,
    assistantInfo
  } = userData;

  const assistant = assistantInfo?.officer
    ? [parseLoanOfficer(assistantInfo?.officer)]
    : assistantInfo?.assistantOf?.map(parseLoanOfficer);

  return {
    isActive,
    profilePicture,
    image: null,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    title: officerInfo?.title,
    url_name: officerInfo?.urlName,
    role,
    nmls: officerInfo?.nmls,
    region: officerInfo?.region,
    branchUuid: officerInfo?.branches?.[0]?.uuid,
    branches: [],
    email,
    secondaryEmail: officerInfo?.secondaryEmail,
    phone_primary: primaryPhone,
    los_name: parseString(officerInfo?.losName),
    crmd_uid: parseString(officerInfo?.crmdUid),
    credit_login_username: parseString(officerInfo?.creditReportUsername),
    credit_login_password: parseString(officerInfo?.creditReportPassword),
    loan_officer:
      assistant?.map?.((assistant) => ({
        ...assistant,
        name: assistant.name,
        uuid: assistant.id
      })) ?? [],
    receive_welcome_email: userData.settings.sendWelcomeEmail ?? true,
    assignedOfficers: officerInfo.automaticAssignment?.map?.(
      ({ officerUUID, states }) => {
        return {
          officer: {
            uuid: officerUUID
          },
          state: states.map((s) => ({ abbreviation: s }))
        };
      }
    ),
    enable_automatic_assignment:
      officerInfo.automaticAssignmentEnabled ?? false,
    pricing_engine_username: officerInfo?.pricingEngineUsername ?? '',
    enable_plaid: userData?.settings?.plaidEnabled ?? false,
    enable_credit_score: userData?.settings?.isCreditScoreEnabled ?? false,
    enable_rate_quote: userData?.settings?.isRateQuoteEnabled,
    enable_du: userData?.settings?.isDuEnabled ?? false,
    enable_du_prequal: userData?.settings?.duAutomaticPreApproval,
    loans_notifications: userData?.settings?.loansNotifications,
    tasks_notifications: userData?.settings?.tasksNotifications,
    documents_notifications: userData?.settings?.documentsNotifications,
    messages_notifications: userData?.settings?.messagesNotifications,
    sms_notifications: userData?.settings?.smsNotifications
  };
}
