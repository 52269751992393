import { IRoles } from 'services/models/IRoles';
import { ILoanOfficer } from 'models/LoanOfficer';
import { ILoanStatus } from '../models/ILoanStatus';
import { IUser } from '../models/IUser';

export function parseLoanOfficer(loanOfficer: IUser): ILoanOfficer {
  return {
    ...loanOfficer,
    id: loanOfficer.uuid,
    name: `${loanOfficer.firstName} ${loanOfficer.middleName || ''} ${
      loanOfficer.lastName
    }`,
    image: loanOfficer.profilePicture,
    title: loanOfficer.officerInfo?.title || 'roles.loan_officer',
    email: loanOfficer.email,
    phone: loanOfficer.primaryPhone,
    nmls: loanOfficer.officerInfo?.nmls
  };
}

export function parseLoanOfficers(
  requestLoanOfficers: Array<IUser>
): Array<ILoanOfficer> {
  return requestLoanOfficers?.map((loanOfficer) => {
    return parseLoanOfficer(loanOfficer);
  });
}

export function parseSelectedLoanOfficer(
  requestLoanStatus: ILoanStatus
): ILoanOfficer | any {
  const loanUsers = requestLoanStatus?.loanUsers;
  const hasLoanUsers = loanUsers?.length > 1;
  if (!hasLoanUsers) return null;
  const officer = loanUsers.find(({ user }) => {
    return user?.role === IRoles.OFFICER;
  });

  if (!officer) return null;
  const formOfficer = parseLoanOfficer(officer.user);

  return formOfficer;
}
